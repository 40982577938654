import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import useStyles from "./styles";
import { useDashboardContext } from "../../utils/contexts/DashboardProvider";
import PropTypes from "prop-types";
import { useOrganizationsContext } from "../../utils/contexts/OrganizationsProvider";

/**
 * Component Description:
 * This component define the map in the Dasboard Container.
 */
function LeafletMap() {
  const classes = useStyles();
  const { listCollectPoints } = useOrganizationsContext();
  const markerIcon = new L.icon({
    iconUrl: process.env.PUBLIC_URL + "/leaflet-images/pin.png",
    iconSize: [40, 40], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [30, 30], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-10, -30],
  });

  let { selectedCollectPoint, infosStockMap } = useDashboardContext();

  return (
    <MapContainer
      center={[14.5403495, -14.8868104]}
      zoom={6}
      scrollWheelZoom={true}
      className={classes.leafletContainer}
      //Resolve popup not showing in mobile view
      tap={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {listCollectPoints.length > 0 &&
        listCollectPoints.map((gpsLoc, index) => {
          if (selectedCollectPoint === "null") {
            return (
              <Marker
                position={[
                  gpsLoc.latitude !== null ? gpsLoc.latitude : "",
                  gpsLoc.longitude !== null ? gpsLoc.longitude : "",
                ]}
                icon={markerIcon}
                key={index}
              >
                <Popup>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                    }}
                  >
                    {gpsLoc.nom}
                  </span>
                  <br />
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "0.9rem",
                      fontFamily: "Poppins",
                    }}
                    key={index}
                  >
                    <span>Téléphone: {gpsLoc.telephone} </span>
                    <span>Superficie: {gpsLoc.superficie} </span>
                    <span>
                      Zone:
                      {` ${gpsLoc.zone?.region} | ${gpsLoc.zone?.departement} | ${gpsLoc.zone?.commune}`}
                    </span>
                  </p>
                </Popup>
              </Marker>
            );
          } else if (gpsLoc.nom === selectedCollectPoint) {
            return (
              <Marker
                position={[
                  gpsLoc.latitude !== null ? gpsLoc.latitude : "",
                  gpsLoc.longitude !== null ? gpsLoc.longitude : "",
                ]}
                icon={markerIcon}
                key={index}
              >
                <Popup>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                    }}
                  >
                    {gpsLoc.nom}
                  </span>
                  <br />
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "0.9rem",
                      fontFamily: "Poppins",
                    }}
                    key={index}
                  >
                    <span>Téléphone: {gpsLoc.telephone} </span>
                    <span>Superficie: {gpsLoc.superficie} </span>
                    <span>
                      Zone:
                      {` ${gpsLoc.zone?.region} | ${gpsLoc.zone?.departement} | ${gpsLoc.zone?.commune}`}
                    </span>
                  </p>
                </Popup>
              </Marker>
            );
          } else {
            return null;
          }
        })}
    </MapContainer>
  );
}
LeafletMap.propTypes = {
  /**
   * Decription:
   * arrays of collect points.
   */
  listCollectPoints: PropTypes.arrayOf(PropTypes.object),
};
export default LeafletMap;

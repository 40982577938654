import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    width: "100%",
    minHeight: "78vh",
    flexDirection: "column",
    padding: "0 20px 0 20px",
    alignItems: "center",
    marginTop: "20px",
    backgroundColor: "#ffffffef",
  },
});

export default useStyles;

import makeStyles from "@mui/styles/makeStyles";
import { COLORS } from "../../utils/constants";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    width: "100%",
    minHeight: "12vh",
    backgroundColor: COLORS.BACKGROUND_FOOTER,
    color: "white",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default useStyles;

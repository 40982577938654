import React from "react";
import { toast } from "react-toastify";
import {
  CREATE_COLLECT_POINT_URL,
  LIST_AGREGATORS_DATA_URL,
  LIST_COMMERCANTS_DATA_URL,
  LIST_COXEURS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
  LIST_PRODUCTORS_DATA_URL,
} from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useAuthenticationContext } from "./AuthenticationProvider";
import { useUpdatePagesStateContext } from "./UpdatePagesStateProvider";

const OrganizationsContext = React.createContext();

export function useOrganizationsContext() {
  return React.useContext(OrganizationsContext);
}

function OrganizationsProvider({ children }) {
  const mountedRef = React.useRef(true);
  const [listCoxeurs, setListCoxeurs] = React.useState([]);
  const [listAgregators, setListAgregators] = React.useState([]);
  const [listCommercants, setListCommercants] = React.useState([]);
  const [listProductors, setListProductors] = React.useState([]);
  const [listGroupments, setListGroupments] = React.useState([]);
  const [listCollectPoints, setListCollectPoints] = React.useState([]);
  const { token } = useAuthenticationContext();
  const {
    isListAgregatorsUpdated,
    setIsListAgregatorsUpdated,
    setIsListGroupmentsUpdated,
    setIsListProductorsUpdated,
    setIsListCommercantsUpdated,
    setIsListCoxeursUpdated,
    setIsListCollectPointsUpdated,
  } = useUpdatePagesStateContext();

  // Clean-up function when the component is unmounted
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const createAgregator = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(LIST_AGREGATORS_DATA_URL, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListAgregatorsUpdated(true);
        toast.success("Agrégateur crée!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListAgregatorsUpdated, token]
  );

  const deleteAgregator = React.useCallback(
    async (row) => {
      try {
        await apiInstance.delete(LIST_AGREGATORS_DATA_URL + `${row.id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListAgregatorsUpdated(true);
        toast.success("Agrégateur supprimé!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListAgregatorsUpdated, token]
  );

  const updateAgregator = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.patch(LIST_AGREGATORS_DATA_URL + `${id}/`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListAgregatorsUpdated(true);
        toast.success("Agrégateur mis a jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListAgregatorsUpdated, token]
  );

  const createGroupment = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(LIST_GROUPMENTS_DATA_URL, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListGroupmentsUpdated(true);
        toast.success("Groupement crée!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListGroupmentsUpdated, token]
  );

  const updateGroupment = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.patch(LIST_GROUPMENTS_DATA_URL + `${id}/`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListGroupmentsUpdated(true);
        toast.success("Groupement mis à jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListGroupmentsUpdated, token]
  );

  const deleteGroupment = React.useCallback(
    async (data) => {
      try {
        await apiInstance.delete(LIST_GROUPMENTS_DATA_URL + `${data.id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListGroupmentsUpdated(true);
        toast.success("Groupement supprimé!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListGroupmentsUpdated, token]
  );

  const createProductor = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(LIST_PRODUCTORS_DATA_URL, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListProductorsUpdated(true);
        toast.success("Producteur crée!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListProductorsUpdated, token]
  );

  const updateProductor = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.patch(LIST_PRODUCTORS_DATA_URL + `${id}/`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListProductorsUpdated(true);
        toast.success("Producteur mis à jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListProductorsUpdated, token]
  );

  const deleteProductor = React.useCallback(
    async (data) => {
      try {
        await apiInstance.delete(LIST_PRODUCTORS_DATA_URL + `${data.id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListProductorsUpdated(true);
        toast.success("Producteur supprime!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListProductorsUpdated, token]
  );

  const createCommercant = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(LIST_COMMERCANTS_DATA_URL, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListCommercantsUpdated(true);
        toast.success("Commerçant crée!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCommercantsUpdated, token]
  );

  const updateCommercant = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.patch(LIST_COMMERCANTS_DATA_URL + `${id}/`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListCommercantsUpdated(true);
        toast.success("Commerçant mis à jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCommercantsUpdated, token]
  );

  const deleteCommercant = React.useCallback(
    async (data) => {
      try {
        await apiInstance.delete(LIST_COMMERCANTS_DATA_URL + `${data.id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!mountedRef.current) return null;
        setIsListCommercantsUpdated(true);
        toast.success("Commerçant supprimé!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCommercantsUpdated, token]
  );

  const createCollectPoint = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(CREATE_COLLECT_POINT_URL, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListCollectPointsUpdated(true);
        toast.success("Point de Collecte crée!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.log(err);
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCollectPointsUpdated, token]
  );

  const updateCollectPoint = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.patch(CREATE_COLLECT_POINT_URL + `${id}/`, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListCollectPointsUpdated(true);
        toast.success("Point de Collecte mis à jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.log(err);
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCollectPointsUpdated, token]
  );

  const deleteCollectPoint = React.useCallback(
    async (row) => {
      try {
        await apiInstance.delete(CREATE_COLLECT_POINT_URL + `${row.id}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListCollectPointsUpdated(true);
        toast.success("Point de Collecte supprimé!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.log(err);
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCollectPointsUpdated, token]
  );

  const createCoxeur = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(LIST_COXEURS_DATA_URL, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListCoxeursUpdated(true);
        toast.success("Coxeur crée!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCoxeursUpdated, token]
  );

  const updateCoxeur = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.patch(LIST_COXEURS_DATA_URL + `${id}/`, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListCoxeursUpdated(true);
        toast.success("Coxeur mis a jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.log(err);
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCoxeursUpdated, token]
  );

  const deleteCoxeur = React.useCallback(
    async (row) => {
      try {
        await apiInstance.delete(LIST_COXEURS_DATA_URL + `${row.id}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListCoxeursUpdated(true);
        toast.success("Coxeur supprimé!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.log(err);
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListCoxeursUpdated, token]
  );

  return (
    <OrganizationsContext.Provider
      value={{
        listAgregators,
        listProductors,
        listGroupments,
        listCommercants,
        listCollectPoints,
        listCoxeurs,
        createAgregator,
        updateAgregator,
        deleteAgregator,
        isListAgregatorsUpdated,
        createGroupment,
        updateGroupment,
        deleteGroupment,
        createProductor,
        updateProductor,
        deleteProductor,
        createCommercant,
        updateCommercant,
        deleteCommercant,
        createCollectPoint,
        deleteCollectPoint,
        updateCollectPoint,
        updateCoxeur,
        createCoxeur,
        deleteCoxeur,
        setListAgregators,
        setListGroupments,
        setListProductors,
        setListCommercants,
        setListCoxeurs,
        setListCollectPoints,
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
}

export default OrganizationsProvider;

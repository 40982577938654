import React from "react";
import { toast } from "react-toastify";
import { LOGIN_URL, LOGOUT_URL } from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useHistory } from "react-router-dom";

const AuthenticationContext = React.createContext();

export function useAuthenticationContext() {
  return React.useContext(AuthenticationContext);
}

function AuthenticationProvider({ children }) {
  const history = useHistory();
  const [token, setToken] = React.useState(
    localStorage.getItem("token") === null ? "" : localStorage.getItem("token")
  );
  const [isLogin, setIsLogin] = React.useState(
    localStorage.getItem("isLogin") === null
      ? false
      : localStorage.getItem("isLogin")
  );
  const [user, setUser] = React.useState(
    localStorage.getItem("my_user") === null
      ? {}
      : JSON.parse(localStorage.getItem("my_user"))
  );

  const handleLogin = async (values) => {
    try {
      const result = await apiInstance.post(LOGIN_URL, values);
      if (result.status === 200) {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("my_user", JSON.stringify(result.data.my_user));
        localStorage.setItem("isLogin", true);
        setIsLogin(true);
        setToken(result.data.token);
        setUser(result.data.my_user);
        toast.success("Vous êtes connecté!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/dashboard");
      }
    } catch (err) {
      // toast.error(JSON.stringify(err.response.data), {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
  };

  const handleLogout = async () => {
    try {
      const result = await apiInstance.get(LOGOUT_URL, {
        headers: { Authorization: `Token ${token}` },
      });

      // Delete the token from the header (With this method by default each instance of 'apiInstance will contain the token')
      delete apiInstance.defaults.headers.common["Authorization"];
      localStorage.clear();
      setIsLogin(false);
      setToken("");
      return result;
    } catch (err) {
      delete apiInstance.defaults.headers.common["Authorization"];
      localStorage.clear();
      setIsLogin(false);
      setToken("");
      return err;
    }
  };
  // React.useEffect(() => {
  //   let user = localStorage.getItem("my_user");
  //   if (
  //     token === "" ||
  //     token === null ||
  //     token === undefined ||
  //     user === null ||
  //     user === undefined
  //   ) {
  //     setIsLogin(false);
  //     setToken("");
  //     localStorage.clear();
  //   } else {
  //     apiInstance
  //       .get("/accounts/user", {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //       .catch((err) => {
  //         if (err) {
  //           setIsLogin(false);
  //           setToken("");
  //           localStorage.clear();
  //           toast.error("Reconnectez-Vous!", {
  //             position: "top-center",
  //             autoClose: 2000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //         }
  //       });
  //   }
  // }, [token, setIsLogin]);

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        user,
        isLogin,
        setToken,
        setUser,
        setIsLogin,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationProvider;

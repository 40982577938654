export const LOGIN_URL = "/accounts/login";
export const LOGOUT_URL = "/accounts/logout";
export const PRODUCTEURS_URL = "/stock/producteurs/";
export const LIST_DASHBOARD_INPUT = "/stats/dashboard-filters/";
export const DOUGHNUTS_DATA_URL = "/stats/cadres/";
export const COURBE_DATA_URL = "/stats/courbe/";
export const MAP_DATA_URL = "/stats/senegal-map/";
export const LIST_AGREGATORS_DATA_URL = "/stock/agregateurs/";
export const CREATE_AGREGATOR_URL = "/stock/agregateurs/";
export const DELETE_AGREGATOR_URL = "/stock/agregateurs/";
export const UPDATE_AGREGATOR_URL = "/stock/agregateurs/";
export const LIST_PRODUCTORS_DATA_URL = "/stock/producteurs/";
export const LIST_GROUPMENTS_DATA_URL = "/stock/groupements/";
export const LIST_USERS_DATA_URL = "/accounts/myusers/";
export const CREATE_USER = "/accounts/register";
export const LIST_USERS_ROLES_URL = "/users/roles/";
export const LIST_COLLECT_POINTS_DATA_URL = "/stock/pointcollectes/";
export const LIST_ZONES_DATA_URL = "/stock/zones/";
export const CREATE_COLLECT_POINT_URL = "/stock/pointcollectes/";
export const LIST_COXEURS_DATA_URL = "/stock/coxeurs/";
export const LIST_STOCKS_DATA_URL = "/stock/stocks/";
export const LIST_PRODUCTS_DATA_URL = "/stock/produits/";
export const LIST_VARIETIES_DATA_URL = "/stock/varietes/";
export const LIST_MARKETS_DATA_URL = "/stock/marches/";
export const LIST_MARKETS_PRICES_DATA_URL = "/stock/situationmarches/";
export const LIST_CODES_DOMMAGES_URL = "/stock/codedommages/";
export const LIST_COMMERCANTS_DATA_URL = "/stock/commercants/";
export const LIST_ENTREE_STOCKS_DATA_URL = "/stock/entreestocks/";
export const LIST_SORTIE_DOMMAGES_DATA_URL = "/stock/sortiedommages/";
export const LIST_LETTRE_VOITURES_DATA_URL = "/stock/lettrevoitures/";
export const STATS_SORTIES = "/stats/sorties/";
export const STATS_ENTREES = "/stats/entrees/";
export const STATS_DOMMAGES = "/stats/dommages/";
export const STATS_MVTS = "/stats/mvts_dates/";

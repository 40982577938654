import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganizationIcon from "@mui/icons-material/Language";
import useStyles from "./styles";
import useCommonStyles from "../styles";

function NavOrganizations({ role }) {
  const classes = useStyles();
  const commonNavItemsClasses = useCommonStyles();
  return (
    <div className={classes.navOrganizations}>
      <OrganizationIcon
        color="inherit"
        className={commonNavItemsClasses.icon}
        fontSize="large"
      />
      <NavDropdown
        title={<span className="text-white">Organisations</span>}
        id="basic-nav-dropdown"
        className={commonNavItemsClasses.navDropDown}
        color="inherit"
      >
        <NavDropdown.Item as="div">
          <Link
            to="/organisations/agregateurs"
            className={commonNavItemsClasses.link}
          >
            Agrégateurs
          </Link>
        </NavDropdown.Item>

        <NavDropdown.Item as="div">
          <Link
            to="/organisations/groupements"
            className={commonNavItemsClasses.link}
          >
            Groupements
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link
            to="/organisations/producteurs"
            className={commonNavItemsClasses.link}
          >
            Producteurs
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link
            to="/organisations/commercants"
            className={commonNavItemsClasses.link}
          >
            Commerçants
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link
            to="/enregistrements/coxeurs"
            className={commonNavItemsClasses.link}
          >
            Coxeurs
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link
            to="/organisations/points-collecte"
            className={commonNavItemsClasses.link}
          >
            Points Collecte
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

export default NavOrganizations;

import React from "react";
import useStyles from "./styles";

/**Component Descripton:
 * This component is used to render the footer in the UI layout
 */

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <h2>© 2021 - SysStocks</h2>
    </div>
  );
}

export default Footer;

import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useCommonStyles from "../styles";

function NavDashboard() {
  const commonNavItemsClasses = useCommonStyles();
  return (
    <React.Fragment>
      <Nav.Link as="div">
        <Link
          className={`text-white ${commonNavItemsClasses.link}`}
          to="/dashboard"
        >
          <DashboardIcon
            className={commonNavItemsClasses.icon}
            color="inherit"
            fontSize="large"
          />
          Tableau de bord
        </Link>
      </Nav.Link>
    </React.Fragment>
  );
}

export default NavDashboard;
